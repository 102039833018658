<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="flex-column" v-if="resourceServer && !loading">
      <v-row>
        <v-col style="width: 50%">
          <v-card-title>ResourceServer:</v-card-title>
          <v-list-item>
            <v-list-item-content>
              <v-container fluid>
                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("id") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer.resourceServerId }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("name") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer.resourceName }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("code") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer.resourceCode }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("clusterCode") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer.clusterCode }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("_uri") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer._uri }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("origin") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer.resourceOrigin }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>Scopes:</b>
                  </v-col>
                  <v-col class="flex-column">
                    <v-list-item
                      v-for="item in scopes"
                      :key="item.scopeId"
                      class="pa-0"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item class="pa-0">{{
                          item.scopeName
                        }}</v-list-item>
                        <v-list-item-subtitle class="text-wrap">
                          <span>{{ item._uri }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("identityId") }}:</b>
                  </v-col>
                  <v-col>{{ resourceServer.identityId }}</v-col>
                </v-row> -->

                <v-row v-if="resourceServer.ownedBy">
                  <v-col class="col-sm-3">
                    <b>{{ $t("ownedBy") }}:</b>
                  </v-col>
                  <v-col>
                    <div class="d-flex flex-column">
                      {{ resourceServer.ownedBy.name | empty }}
                      <span class="caption">
                        {{ resourceServer.ownedBy._uri | empty }}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="col-sm-3">
                    <b>{{ $t("createdAt") }}:</b>
                  </v-col>
                  <v-col>
                    {{ resourceServer.createdAt | getDisplayDate }}
                    <span v-if="resourceServer.createdBy?.name">
                      - {{ resourceServer.createdBy.name }}
                    </span>
                  </v-col>
                </v-row>

                <v-row v-if="resourceServer.updatedAt">
                  <v-col class="col-sm-3">
                    <b>{{ $t("updatedAt") }}:</b>
                  </v-col>
                  <v-col>
                    {{ resourceServer.updatedAt | getDisplayDate }}
                    <span v-if="resourceServer.updatedBy?.name">
                      - {{ resourceServer.updatedBy.name }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <gs-resource-server-login-status
                      :resource-server="resourceServer"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col style="width: 50%">
          <v-card-title>Resource Identities:</v-card-title>

          <gs-entity-link
            :label="$t('resourceIdentity')"
            entity-name="serverIdentity"
            :value="resourceServer.identityId?.name | empty"
            :uri="resourceServer.identityId?._uri | empty"
            :actions="identityActions"
            :on-activate="this.action_activate"
            actionIcon="mdi-account-key-outline"
          />

          <gs-entity-link
            :label="$t('resourceServer')"
            entity-name="resourceServer"
            :value="resourceServer.resourceName | empty"
            :uri="resourceServer._uri | empty"
            :actions="serverActions"
            :on-activate="this.action_activate"
            actionIcon="mdi-server"
          />

          <gs-entity-link
            :label="$t('resourceScope')"
            entity-name="scope"
            :value="resourceServer.resourceScope?.scopeName | empty"
            :uri="resourceServer.resourceScope?._uri | empty"
            :actions="scopeActions"
            :on-activate="this.action_activate"
            actionIcon="mdi-web"
          />

          <gs-entity-link
            :label="$t('resourceClient')"
            entity-name="client"
            :value="resourceServer.resourceClient?.client_name | empty"
            :uri="resourceServer.resourceClient?._uri | empty"
            :actions="clientActions"
            :on-activate="this.action_activate"
            actionIcon="mdi-monitor-cellphone"
          />

          <gs-entity-link
            :label="$t('resourceClientIdentity')"
            entity-name="clientIdentity"
            :value="resourceServer.resourceClient?.identityId?.name | empty"
            :uri="resourceServer.resourceClient?.identityId?._uri | empty"
            :actions="identityActions"
            :on-activate="this.action_activate"
            actionIcon="mdi-account-key-outline"
          />

          <div class="flex-column">
            <v-card-title>Attributes:</v-card-title>
            <GsAttributesDisplay
              v-if="resourceServer"
              :entityId="resourceServer.resourceServerId"
              entityName="resource-servers"
              collection="resourceServer"
            />
            <v-card-title>Tags:</v-card-title>
            <GsTagsDisplay
              v-if="resourceServer"
              :id="resourceServer.resourceServerId"
              endpoint="/resource-servers"
              collection="resourceServer"
              tagType="dp"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!resourceServer && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsAttributesDisplay from "@/components/AttributeEditor/GsAttributesDisplay";
import GsTagsDisplay from "@/components/AttributeEditor/GsTagsDisplay";
import GsEntityLink from "@/components/GsEntityLink.vue";
import GsResourceServerLoginStatus from "@/components/GsResourceServerLoginStatus.vue";
import { GsActionsMenu } from "ngt-frontend-core";
import clients from "@/views/Clients.vue";

export default {
  name: "GsResourceServerDetails",
  computed: {
    clients() {
      return clients;
    },
    loginStatus() {
      if (!this.resourceServer.lastLogin) {
        return { color: "info", icon: "mdi-minus-circle-outline" };
      }

      const age =
        new Date().getTime() -
        new Date(parseInt(this.resourceServer.lastLogin, 10)).getTime();
      if (age > 86400 * 1000) {
        return { color: "error", icon: "mdi-alert-circle" };
      }
      if (age > 600 * 1000) {
        return { color: "warning", icon: "mdi-alert-circle-outline" };
      }

      return {
        color: "success",
        icon: "mdi-check"
      };
    },
    dhKeyStatus() {
      const statusIcon = {
        color: "error",
        icon: "mdi-key"
      };

      if (this.resourceServer?.identityId?.keyInfo?.dh?.exp) {
        const timeLeft =
          new Date(parseInt(this.resourceServer.identityId.keyInfo.dh.exp, 10)).getTime() -
          new Date().getTime();

        if (timeLeft <= 0) {
          statusIcon.color = "error";
        } else if (timeLeft < 86400 * 30) {
          statusIcon.color = "warning";
        } else {
          statusIcon.color = "success";
        }
      }
      return statusIcon;
    },
    dsaKeyStatus() {
      const statusIcon = {
        color: "error",
        icon: "mdi-key"
      };

      if (this.resourceServer?.identityId?.keyInfo?.dh?.exp) {
        const timeLeft =
          new Date(parseInt(this.resourceServer.identityId.keyInfo.dh.exp, 10)).getTime() -
          new Date().getTime();

        if (timeLeft <= 0) {
          statusIcon.color = "error";
        } else if (timeLeft < 86400 * 30) {
          statusIcon.color = "warning";
        } else {
          statusIcon.color = "success";
        }
      }
      return statusIcon;
    },
  },
  components: {
    GsResourceServerLoginStatus,
    GsEntityLink,
    GsActionsMenu,
    GsAttributesDisplay,
    GsTagsDisplay
  },
  props: {
    resourceServer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      scopes: [],
      loading: true,
      identityActions: ["tokens", "generate_token", "edit"],
      scopeActions: ["edit"],
      clientActions: ["edit"],
      serverActions: ["edit"]
    };
  },
  created: async function() {
    this.getScopes(this.resourceServer);
  },
  watch: {
    resourceServer(value) {
      this.getScopes(value);
    }
  },
  methods: {
    idFromUri(uri) {
      return /((\w{4,12}-?)){5}/.exec(uri)[0];
    },
    async getScopes(resourceServer) {
      this.loading = true;
      if (!resourceServer?.scope?.length) {
        this.scopes = [];
        this.loading = false;
        return;
      }
      this.scopes = await this.getScope(resourceServer.scope, "");
      this.loading = false;
    },
    editIdentity(id) {
      this.$router.push_safe(`/identities/${id}`);
    },
    editResourceServer(id) {
      this.$router.push_safe(`/resource-servers/${id}`);
    },
    editScope(id) {
      this.$router.push_safe(`/scopes/${id}`);
    },
    editClient(id) {
      this.$router.push_safe(`/clients/${id}`);
    },
    listToken(id, tokenType = 'access.token') {
      this.$router.push_safe(`/identities/${id}/tokens?tokenType=${tokenType}`);
    },
    generateResourceLoginToken(identityId) {
      return callAPI({
        url: `/identities/${identityId}/transactions`,
        method: "POST",
        data: {
          action: 'identity.action.issue-token',
          inputValues: {
            tokenType: 'resource.login',
          },
        }
      });
    },
    generateAccessToken(identityId) {
      if (this.resourceServer.resourceClient?._uri && this.resourceServer.resourceScope?._uri) {
        return callAPI({
          url: `/identities/${identityId}/transactions`,
          method: "POST",
          data: {
            action: 'identity.action.issue-token',
            inputValues: {
              tokenType: 'access.token',
              client_id: this.idFromUri(this.resourceServer.resourceClient._uri),
              scope: this.resourceServer.resourceScope._uri,
            },
          }
        });
      }
    },
    async action_activate({ actionId, entityName, uri }) {
      if (["serverIdentity", "clientIdentity"].includes(entityName)) {
        switch (actionId) {
          case "edit":
            this.editIdentity(this.idFromUri(uri));
            break;
          case "tokens":
            this.listToken(
              this.idFromUri(uri),
              entityName === "serverIdentity" ? "resource.login" : "access.token"
            );
            break;
          case "generate_token":
            if (entityName === "serverIdentity") {
              await this.generateResourceLoginToken(this.idFromUri(uri));
            } else if (entityName === "clientIdentity") {
              await this.generateAccessToken(this.idFromUri(uri));
            }

            break;
        }
      } else if (entityName === "resourceServer") {
        switch (actionId) {
          case "edit":
            this.editResourceServer(this.idFromUri(uri));
            break;
        }
      } else if (entityName === "scope") {
        switch (actionId) {
          case "edit":
            this.editScope(this.idFromUri(uri));
            break;
        }
      } else if (entityName === "client") {
        switch (actionId) {
          case "edit":
            this.editClient(this.idFromUri(uri));
            break;
        }
      }
    }
  }
};
</script>
